import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import {
  CfCustomerTestimonial
} from '@mycs/contentful';
import { calculateAverage } from 'mycs/shared/utilities/RatingUtils/RatingUtils';
import styles from './CustomerTestimonials.scss';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import CustomerTestimonial from './CustomerTestimonial/CustomerTestimonial';
import StarsRating from 'mycs/shared/components/StarsRating/StarsRating';

export default function CustomerTestimonials({
}: {
  }) {
  const { locale, countryCode } = useLocale();
  const [customerReviews, setCustomerReviews] = useState<CfCustomerTestimonial[] | []>(
    []
  );
  const [avgScore, setAvgScore] = useState<number | null>(null);
  const [reviewsAmount, setReviewsAmount] = useState<number | null>(null);

  useEffect(() => {
    ContentfulService.getCustomerTestimonialsList(locale, countryCode).then((data) => {
      //ignore invalid data
      if (!data || !Array.isArray(data)) {
        return;
      }

      setCustomerReviews(data);
      const testimonials: CfCustomerTestimonial[] = data.filter((review: CfCustomerTestimonial) => review.showOnWebsite)
      const average = calculateAverage(testimonials.map((review: CfCustomerTestimonial) => review.rating));
      setAvgScore(average);
      setReviewsAmount(testimonials.length);
    });
  }, [locale, countryCode, setCustomerReviews]);

  const renderPages = () => {
    return <div>{renderCustomerReviews(customerReviews)}</div>;
  };

  const renderCustomerReviews = (list: typeof customerReviews) => {
    return list?.map((review) => {
      return <CustomerTestimonial key={review._id} review={review} />;
    });
  };

  return (
    <div
      className={classNames(styles.container, 'contentContainer')}
      id="customer-reviews"
    >
      <div className={styles.header}>
        <h3 className={styles.title}>
          {I18nUtils.localize(locale, 'Our customers say')}
        </h3>
        <div className={styles.scoreContainer}>
          {
            !!avgScore &&
            <div className={styles.score}>
              <span>{I18nUtils.localize(locale, 'Rate')}: {avgScore}/5</span>
              <div className={styles.starsContainer}>
                <StarsRating rating={avgScore} starClassName={styles.starTitle} />
              </div>
            </div>
          }
        </div>
        <span className={styles.basedOn}>{I18nUtils.localize(locale, 'based on')} {reviewsAmount} {I18nUtils.localize(locale, 'reviews')}</span>
      </div>
      {renderPages()}
    </div>
  );
}
