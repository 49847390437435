import { useLocale } from 'mycs/shared/state/LocaleContext';
import {
  CfCustomerTestimonial
} from '@mycs/contentful';
import CfImage from 'mycs/shared/components/CfImage/CfImage';
import StarsRating from 'mycs/shared/components/StarsRating/StarsRating';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

import styles from './CustomerTestimonial.scss';
import ContentfulUtils from 'mycs/shared/utilities/ContentfulUtils/ContentfulUtils';

function formatDate(dateString: string, locale: string): string {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  return locale.includes('de') ? formattedDate.replace('.', '') : formattedDate;
}

export default function CustomerTestimonial({
  review,
}: {
  review: CfCustomerTestimonial;
}) {
  const { testimonialText, rating, showOnWebsite, customerName, date, photo } = review;
  const { locale } = useLocale();
  const localisation = locale.replace('_', '-');
  const formattedDate = formatDate(date || '', localisation);
  const img = ContentfulUtils.getImage({
    image: photo,
    imageTablet: photo,
    imagePhone: photo,
  });

  return (
    showOnWebsite &&
    <div className={styles.review}>
      <div>
        <div className={styles.reviewInfo}>
          <div className={styles.customerName}>
            <span>{I18nUtils.localize(locale, 'Rate')}: {rating}/5</span>
          </div>
          <StarsRating rating={rating} className={styles.stars} />
          <span className={styles.date}>{formattedDate}</span>
        </div>
        <div className={styles.reviewText}>
          <h3 className={styles.customerName}>{customerName}</h3>
          <p className={styles.text}>{testimonialText}</p>
        </div>
      </div>
      <div className={styles.imageWrapper}>
        {img && <CfImage className={styles.fitImage} asset={img} />}
      </div>
    </div>
  ) || <div></div>;
}
