import { filter, take, map } from 'rxjs/operators';

import cfg from 'mycs/config';
import CookieService from 'mycs/shared/services/CookieService/CookieService';
import LocationUtils from 'mycs/shared/utilities/LocationUtils/LocationUtils';
import Logger from 'mycs/shared/services/Logger';
import WindowLocationUtils from 'mycs/shared/utilities/WindowLocationUtils/WindowLocationUtils';

const endpointLandingpage = cfg.mentionMe.endpointLandingpage;
const endpointCheckout = cfg.mentionMe.endpointCheckout;
const endpointPostPurchase = cfg.mentionMe.endpointPostPurchase;

export function isEnabled() {
  const countryCode = LocationUtils.getCountryCodeFromUrl(
    WindowLocationUtils.getCurrentUrl()
  );
  const isEnabledCountry = ['de', 'fr'].includes(countryCode);

  if (!isEnabledCountry) {
    return Promise.resolve(false);
  }

  return CookieService.state$
    .pipe(
      filter((state) => state.isInitialized),
      take(1),
      map((state) => Boolean(state.cookieConsent?.preferences))
    )
    .toPromise();
}

export function cleanUpMMFiredTag({
  implementation,
  situation,
}: {
  implementation: string;
  situation: string;
}) {
  if (window.MentionMeFiredTags?.[implementation + situation]) {
    delete window.MentionMeFiredTags[implementation + situation];
  }
}

export function mentionMeReload() {
  CookieService.renewCookieDialog();

  return;
}

export async function loadMainPageIntegration() {
  const enabled = await isEnabled();

  if (!enabled) {
    const cookieElementContainer = document.getElementById('no-cookie-consent');

    if (!cookieElementContainer?.style?.display) {
      Logger.error('MentionMeService not displaying non-cookie consent');
      return;
    }

    cookieElementContainer.style.display = 'block';

    return;
  }

  const script = document.createElement('script');
  const endpoint = 'MMLandingpage';
  const situation = 'landingpage';
  const implementation = 'embed';
  script.src = buildMentionMeUrl(endpoint, {
    situation,
    implementation,
  });

  script.async = true;
  document.body.appendChild(script);
}

type Params = {
  firstname?: string;
  surname?: string;
  email?: string;
  order_number?: string;
  order_subtotal?: number;
  order_currency?: string;
  situation: string;
  implementation: string;
};

export function buildMentionMeUrl(endpoint: string, params: Params) {
  if (endpoint === 'MMLandingpage') {
    endpoint = endpointLandingpage;
  }
  if (endpoint === 'MMCheckout') {
    endpoint = endpointCheckout;
  }
  if (endpoint === 'MMPostpurchase') {
    endpoint = endpointPostPurchase;
  }

  const locale = LocationUtils.computeLanguageCode(
    window.location.hostname,
    window.location.pathname
  );

  const query = Object.keys(params)
    .map((param) => {
      const value = (params as any)[param];
      return encodeURIComponent(param) + '=' + encodeURIComponent(value);
    })
    .join('&');

  return `${endpoint}` + `${query}` + `&locale=${locale}`;
}
